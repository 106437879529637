<template>
  <HomeLayout>
    <b-container
      class="
        d-flex
        flex-column
        align-items-start
        justify-content-start
        px-0
        shadow-soft
        overflow-hidden
        main-container
      "
    >
      <!-- Target Videos row -->
      <b-row
        class="
          d-flex
          flex-row flex-wrap
          align-items-center
          justify-content-start
          w-100
          px-0
          videos-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            w-100
            videos-card
          "
        >
          <h6
            class="
              text-left text-dark
              font-weight-semi-bold font-primary
              mt-3
              mb-2
              ml-3
            "
          >
            Target videos
            <span class="subHeading">
              (Select up to 6 videos to offer the donors)</span
            >
          </h6>
          <b-row
            class="
              d-flex
              flex-row flex-nowrap
              align-items-center
              justify-content-start
              px-3
              videos-card-scroll
            "
            no-gutters
          >
            <!-- add new prize button -->
            <b-button
              variant="light"
              class="
                d-flex
                flex-column flex-shrink-0
                align-items-center
                justify-content-center
                mr-2
                bg-white
                border border-light
                shadow-sm
                rounded-lg
                video-add-btn
              "
              @click="createVideoPage"
            >
              <font-awesome-icon
                class="text-prime-gray"
                icon="plus"
                size="lg"
                aria-label="Create Prize"
              ></font-awesome-icon>
              <span class="d-inline mt-2 text-prime-gray">Add a new video</span>
              <span class="d-inline mt-2 text-prime-gray">{{
                `${noOfuploadedVideo}/${maxUploadableVideos}`
              }}</span>
            </b-button>
            <template v-if="targetVideoItems.length > 0">
              <template v-for="(targetVideo, index) in targetVideoItems">
                <b-card
                  :key="index"
                  overlay
                  :img-src="targetVideo.videoPreviewImgURL"
                  :img-alt="targetVideo.videoTitle"
                  class="
                    d-flex
                    flex-column flex-shrink-0
                    align-items-start
                    justify-content-center
                    bg-light
                    rounded-lg
                    border border-light
                    shadow-sm
                    overflow-hidden
                    mr-2
                    my-2
                    single-video-card
                  "
                  :class="targetVideo.state ? 'is-enabled' : 'is-disabled'"
                  body-class="d-flex flex-column align-items-start justify-content-end p-0"
                >
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-start
                      justify-content-end
                      position-relative
                      hover-actions
                    "
                    no-gutters
                  >
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-end
                        justify-content-start
                        mx-2 mt-5
                        my-2 
                      "
                    >
                    <!-- demo ad button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Demo Video"
                        size="sm"
                        class="shadow-none my-1 border border-info edit-btn"
                        pill
                        @click="createDemoAd(targetVideo._id)"
                      >
                        <font-awesome-icon
                          icon="video"
                          aria-label="Edit Video"
                          class="edit-icon"
                        ></font-awesome-icon>
                      </b-button>
                      <!-- edit video button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Edit Video"
                        size="sm"
                        class="shadow-none my-1 border border-info edit-btn"
                        pill
                        @click="editVideoFn(targetVideo._id)"
                      >
                        <font-awesome-icon
                          icon="pen"
                          aria-label="Edit Video"
                          class="edit-icon"
                        ></font-awesome-icon>
                      </b-button>

                      <!-- disable button -->
                      <b-button
                        v-b-tooltip.hover.left
                        :title="
                          targetVideo.actionsState.state
                            ? 'Disable Video'
                            : 'Enable Video'
                        "
                        size="sm"
                        class="shadow-none my-1 border disable-btn"
                        :class="
                          targetVideo.actionsState.state
                            ? 'border-dark is-enabled'
                            : 'border-success is-disabled'
                        "
                        pill
                        @click="
                          openEnableDisableModal(
                            targetVideo.actionsState.details,
                            $event.target
                          )
                        "
                      >
                        <font-awesome-icon
                          :icon="
                            targetVideo.actionsState.state ? 'ban' : 'check'
                          "
                          aria-label="Edit Video"
                          class="disable-icon"
                          :class="
                            targetVideo.actionsState.state
                              ? 'text-dark'
                              : 'text-success'
                          "
                          aria-hidden="true"
                        ></font-awesome-icon>
                      </b-button>

                      <!-- delete video button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Delete Video"
                        size="sm"
                        class="shadow-none my-1 border border-danger delete-btn"
                        pill
                        @click="
                          openDeleteItemModal(
                            targetVideo,
                            'video',
                            $event.target
                          )
                        "
                      >
                        <font-awesome-icon
                          icon="trash"
                          aria-label="Delete Video"
                          class="delete-icon"
                        ></font-awesome-icon>
                      </b-button>
                    </b-col>

                    <b-spinner
                      class="spinner"
                      v-if="!targetVideo.ready && targetVideo.waiting"
                    ></b-spinner>
                    <!-- preview video button -->
                    <b-button
                      v-else
                      v-b-tooltip.hover
                      title="Preview Video"
                      size="sm"
                      class="shadow-none my-1 preview-btn"
                      pill
                      @click="openPreviewVideoModal(targetVideo, $event.target)"
                    >
                      <font-awesome-icon
                        icon="play"
                        aria-label="Preview Video"
                        class="play-icon"
                      ></font-awesome-icon>
                    </b-button>
                  </b-row>
                  <b-spinner
                    class="spinner"
                    variant="white"
                    v-if="!targetVideo.ready && targetVideo.waiting"
                  ></b-spinner>
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-end
                      px-3
                      py-2
                    "
                  >
                    <span
                      class="d-block text-left text-small text-white mb-1"
                      >{{ targetVideo.videoTitle }}</span
                    >
                  </b-col>
                </b-card>
              </template>
            </template>
            <template v-else>
              <template v-if="targetVideosLoading">
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <b-spinner></b-spinner>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <span class="text-center font-secondary font-weight-normal">
                    No videos listed
                  </span>
                </div>
              </template>
            </template>
          </b-row>
        </b-card>
      </b-row>
      <!-- Compliance images row -->
      <b-row
        class="
          d-flex
          flex-column
          align-items-start
          justify-content-start
          px-0
          w-100
          slides-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            w-100
            rounded-0
            slides-card
          "
        >
          <h6
            class="
              text-left text-dark
              font-weight-semi-bold font-primary
              mt-3
              mb-2
              ml-3
            "
          >
            Compliance images
          </h6>
          <b-row
            class="
              d-flex
              flex-row flex-nowrap
              align-items-center
              justify-content-start
              px-3
              slides-card-scroll
            "
            no-gutters
          >
            <!-- add new compliance image -->
            <b-button
              variant="light"
              class="
                d-flex
                flex-column flex-shrink-0
                align-items-center
                justify-content-center
                mr-2
                bg-white
                border border-light
                shadow-sm
                rounded-lg
                slide-add-btn
              "
              @click="createImagePage"
            >
              <font-awesome-icon
                class="text-prime-gray"
                icon="plus"
                size="lg"
                aria-label="Create Prize"
              ></font-awesome-icon>
              <span class="d-inline mt-2 text-prime-gray"
                >Add an image background</span
              >
              <span class="d-inline mt-2 text-prime-gray">{{
                `${noOfuploadedImages}/${maxUploadableImages}`
              }}</span>
            </b-button>
            <template v-if="complianceImageItems.length > 0">
              <template
                v-for="(complianceSlide, index) in complianceImageItems"
              >
                <b-card
                  :key="index"
                  overlay
                  :img-src="complianceSlide.slideBackgroundImage"
                  :img-alt="complianceSlide.slideTitle"
                  class="
                    d-flex
                    flex-column flex-shrink-0
                    align-items-start
                    justify-content-center
                    bg-light
                    rounded-lg
                    border border-light
                    shadow-sm
                    overflow-hidden
                    mr-2
                    my-2
                    single-slide-card
                  "
                  body-class="d-flex flex-column align-items-start justify-content-end px-3"
                >
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      px-0
                    "
                  >
                    <h4
                      class="d-block text-center mx-auto"
                      :style="`color: ${
                        complianceSlide.textColor
                          ? complianceSlide.textColor
                          : 'white'
                      }`"
                    >
                      {{ complianceSlide.slideTitle }}
                    </h4>
                  </b-col>
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-start
                      justify-content-end
                      hover-actions
                    "
                    no-gutters
                  >
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-end
                        justify-content-start
                        mx-2
                        my-1
                      "
                    >
                      <!-- edit slide button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Edit Slide"
                        size="sm"
                        class="shadow-none my-1 border border-info edit-btn"
                        pill
                        @click="editSlideFn(complianceSlide._id, 'image')"
                      >
                        <font-awesome-icon
                          icon="pen"
                          aria-label="Edit Slide"
                          class="edit-icon"
                        ></font-awesome-icon>
                      </b-button>
                      <!-- delete slide button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Delete Slide"
                        size="sm"
                        class="shadow-none my-1 border border-danger delete-btn"
                        pill
                        @click="
                          openDeleteItemModal(
                            complianceSlide,
                            'slide',
                            $event.target
                          )
                        "
                      >
                        <font-awesome-icon
                          icon="trash"
                          aria-label="Delete Slide"
                          class="delete-icon"
                        ></font-awesome-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
            <template v-else>
              <template v-if="complianceSlidesLoading">
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <b-spinner></b-spinner>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <span class="text-center font-secondary font-weight-normal">
                    No images listed
                  </span>
                </div>
              </template>
            </template>
          </b-row>
        </b-card>
      </b-row>
      <!-- Compliance slides row -->
      <b-row
        class="
          d-flex
          flex-column
          align-items-start
          justify-content-start
          px-0
          w-100
          slides-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            w-100
            rounded-0
            slides-card
          "
        >
          <h6
            class="
              text-left text-dark
              font-weight-semi-bold font-primary
              mt-3
              mb-2
              ml-3
            "
          >
            Compliance slides
          </h6>
          <b-row
            class="
              d-flex
              flex-row flex-nowrap
              align-items-center
              justify-content-start
              px-3
              slides-card-scroll
            "
            no-gutters
          >
            <!-- add new compliance slide -->
            <b-button
              variant="light"
              class="
                d-flex
                flex-column flex-shrink-0
                align-items-center
                justify-content-center
                mr-2
                bg-white
                border border-light
                shadow-sm
                rounded-lg
                slide-add-btn
              "
              @click="createSlidePage"
            >
              <font-awesome-icon
                class="text-prime-gray"
                icon="plus"
                size="lg"
                aria-label="Create Prize"
              ></font-awesome-icon>
              <span class="d-inline mt-2 text-prime-gray"
                >Add a new slide background</span
              >
              <span class="d-inline mt-2 text-prime-gray">{{
                `${noOfuploadedSlides}/${maxUploadableSlides}`
              }}</span>
            </b-button>
            <template v-if="complianceSlideItems.length > 0">
              <template
                v-for="(complianceSlide, index) in complianceSlideItems"
              >
                <b-card
                  :key="index"
                  overlay
                  :img-src="complianceSlide.slideBackgroundImage"
                  :img-alt="complianceSlide.slideTitle"
                  class="
                    d-flex
                    flex-column flex-shrink-0
                    align-items-start
                    justify-content-center
                    bg-light
                    rounded-lg
                    border border-light
                    shadow-sm
                    overflow-hidden
                    mr-2
                    my-2
                    single-slide-card
                  "
                  body-class="d-flex flex-column align-items-start justify-content-end px-3"
                >
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      px-0
                    "
                  >
                    <h4
                      class="d-block text-center mx-auto"
                      :style="`color: ${
                        complianceSlide.textColor
                          ? complianceSlide.textColor
                          : 'white'
                      }`"
                    >
                      {{ complianceSlide.slideTitle }}
                    </h4>
                  </b-col>
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-start
                      justify-content-end
                      hover-actions
                    "
                    no-gutters
                  >
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-end
                        justify-content-start
                        mx-2
                        my-1
                      "
                    >
                      <!-- edit slide button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Edit Slide"
                        size="sm"
                        class="shadow-none my-1 border border-info edit-btn"
                        pill
                        @click="editSlideFn(complianceSlide._id, 'normal')"
                      >
                        <font-awesome-icon
                          icon="pen"
                          aria-label="Edit Slide"
                          class="edit-icon"
                        ></font-awesome-icon>
                      </b-button>
                      <!-- delete slide button -->
                      <b-button
                        v-b-tooltip.hover.left
                        title="Delete Slide"
                        size="sm"
                        class="shadow-none my-1 border border-danger delete-btn"
                        pill
                        @click="
                          openDeleteItemModal(
                            complianceSlide,
                            'slide',
                            $event.target
                          )
                        "
                      >
                        <font-awesome-icon
                          icon="trash"
                          aria-label="Delete Slide"
                          class="delete-icon"
                        ></font-awesome-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
            <template v-else>
              <template v-if="complianceSlidesLoading">
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <b-spinner></b-spinner>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    px-0
                    py-3
                    w-100
                  "
                  no-gutters
                >
                  <span class="text-center font-secondary font-weight-normal">
                    No slides listed
                  </span>
                </div>
              </template>
            </template>
          </b-row>
        </b-card>
      </b-row>
      <!-- Compliance slide configuration row -->
      <b-row
        class="
          d-flex
          flex-row flex-wrap
          align-items-center
          justify-content-start
          configs-row
          w-100
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            d-flex
            flex-row flex-nowrap
            align-items-start
            justify-content-start
            w-100
            rounded-0
            configs-card
          "
        >
          <SlideConfiguration />
        </b-card>
      </b-row>
      <DeleteModal
        :isShow="isShowDeleteModal"
        @onClose="onCloseDeleteItemModal"
        @deleteVideo="deleteVideoFn"
        @deleteSlide="deleteSlideFn"
        :apiLoader="deleteApiLoading"
        :itemContent="deleteItem"
        :type="deleteType"
      />
      <PreviewModal
        :isShow="isShowPreviewModal"
        @onClose="onClosePreviewVideoModal"
        :itemContent="previewVideoItem"
      />
      <EnableDisableVideo
        :isShow="isShowEnableDisableModal"
        @onClose="onCloseEnableDisableModal"
        :itemContent="rowItem"
        :key="showEnableDisableModalKey"
        @reloadFn="callInitFn"
      />
    </b-container>
  </HomeLayout>
</template>
<script>
// services
import {
  GetAllTargetVideos,
  DeleteTargetVideo,
  WaitForConversion,
} from "@/services/targetVideos.service";
import {
  GetAllComplianceSlides,
  DeleteComplianceSlide,
} from "@/services/complianceSlides.service";
import Vue from "vue";

// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import SlideConfiguration from "@/components/Videos/SlideConfiguration";
import DeleteModal from "@/components/Videos/Modals/DeleteModal";
import PreviewModal from "@/components/Videos/Modals/PreviewModal";
import EnableDisableVideo from "@/components/Videos/Modals/EnableDisableVideo";

export default {
  title: "Videos",
  components: {
    HomeLayout,
    SlideConfiguration,
    DeleteModal,
    PreviewModal,
    EnableDisableVideo,
  },
  data() {
    return {
      isShowDeleteModal: false,
      isShowPreviewModal: false,
      deleteItem: null,
      deleteType: null,
      deleteApiLoading: false,
      previewVideoItem: null,
      rowItem: null,
      searchTable: null,
      showEnableDisableModalKey: Number(new Date()) + 100,

      modalThatBtn: null,
      modalShow: false,
      targetVideosLoading: false,
      targetVideoItems: [],
      isShowEnableDisableModal: false,
      complianceSlidesLoading: false,
      complianceSlideItems: [],
      complianceImageItems: [],
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],

      maxUploadableVideos: 12,
      maxUploadableImages: 12,
      maxUploadableSlides: 12,
      noOfuploadedVideo: "?",
      noOfuploadedSlides: "?",
      noOfuploadedImages: "?",
      noOfEnabledVideos: null,
      maxNoEnabledVideos: 6,
      isSubscribed: false,
    };
  },
  async mounted() {
    this.isSubscribed = Vue.$keycloak.idTokenParsed.subscribed;
    // load all the target videos
    this.getAllTargetVideos();
    // load all the compliance slides
    this.getAllComplianceSlides();
  },
  methods: {
    // fetch functions
    async getAllTargetVideos() {
      try {
        this.targetVideosLoading = true;
        let { data } = await GetAllTargetVideos();
        if (data.code == 200 || data.message == "success") {
          this.targetVideoItems = data.content.results.map((x) => {
            return {
              _id: x._id,
              videoTitle: x.videoTitle,
              videoURL: x.video,
              videoPreviewImgURL: x.videoPreviewImage,
              state: x.isEnabled,
              ready: x.ready,
              actionsState: {
                details: x,
                state: x.isEnabled,
              },
            };
          });

          this.noOfEnabledVideos = this.targetVideoItems.filter(
            (p) => p.state
          ).length;

          this.noOfuploadedVideo = this.targetVideoItems.length;
          if (data.content.maxUploadable)
            this.maxUploadableVideos = data.content.maxUploadable;
          else this.maxUploadableVideos = 12;
          this.targetVideosLoading = false;
        } else {
          this.targetVideosLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Target Videos",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.targetVideosLoading = false;
      }
    },
    async getAllComplianceSlides() {
      try {
        this.complianceSlidesLoading = true;
        this.complianceSlideItems = [];
        this.complianceImageItems = [];
        let { data } = await GetAllComplianceSlides();
        if (data.code == 200 || data.message == "success") {
          for (const item of data.content.results) {
            const fomatted = {
              _id: item._id,
              slideTitle: item.slideTitle,
              textColor: item.textColor,
              slideBackgroundImage: item.slideBackgroundImage,
            };
            if (item.type == "image") {
              this.complianceImageItems.push(fomatted);
            } else {
              this.complianceSlideItems.push(fomatted);
            }
          }
          this.maxUploadableImages = data.content.maxUploadable;
          this.maxUploadableSlides = data.content.maxUploadable;
          this.noOfuploadedSlides = this.complianceSlideItems.length;
          this.noOfuploadedImages = this.complianceImageItems.length;
          this.complianceSlidesLoading = false;
        } else {
          this.complianceSlidesLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Compliance Slides",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.complianceSlidesLoading = false;
      }
    },
    // edit functions
    editVideoFn(id) {
      this.$router.push({ name: "VideosEdit", params: { id: id } });
    },
    createDemoAd(id) {
      this.$router.push({ name: "DemoAdVideo", params: { id: id } });
    },
    editSlideFn(id, type) {
      let routeName = "ComplianceSlideEdit";
      if (type == "image") routeName = "ComplianceImageEdit";
      this.$router.push({ name: routeName, params: { id: id } });
    },

    createVideoPage() {
      if (this.isSubscribed) {
        if (this.noOfuploadedVideo >= this.maxUploadableVideos) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Unable to add video",
            content: "You cannot add more than 12 videos",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          this.$router.push({ name: "VideosCreate" });
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Unable to add video",
          content: "You need an active subscription for this feature",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },

    createImagePage() {
      if (this.isSubscribed) {
        if (this.noOfuploadedImages >= this.maxUploadableImages) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Unable to add Compliance image",
            content: "You cannot add more than 12 images",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          this.$router.push({ name: "ComplianceImageAdd" });
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Unable to add video",
          content: "You need an active subscription for this feature",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },

    createSlidePage() {
      if (this.isSubscribed) {
        if (this.noOfuploadedSlides >= this.maxUploadableSlides) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Unable to add Compliance slide",
            content: "You cannot add more than 12 slides",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          this.$router.push({ name: "ComplianceSlideAdd" });
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Unable to add video",
          content: "You need an active subscription for this feature",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    // delete modal functions
    openDeleteItemModal(item, type, button) {
      this.isShowDeleteModal = true;
      this.deleteItem = item;
      // set the type of delete (video or slide)
      this.deleteType = type;
      this.modalThatBtn = button;
      this.$root.$emit("bv::show::modal", "delete-video-row-modal", button);
    },
    // disable modal function
    openEnableDisableModal(item, button) {
      if (
        !item.isEnabled &&
        this.noOfEnabledVideos >= this.maxNoEnabledVideos
      ) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Unable to enable videos",
          content: "You cannot enable more than 6 videos",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else {
        this.isShowEnableDisableModal = true;
        this.rowItem = item;
        this.$root.$emit(
          "bv::show::modal",
          "diableEnable-video-row-modal",
          button
        );
      }
    },
    onCloseEnableDisableModal() {
      this.isShowEnableDisableModal = false;
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteModal = false;
    },
    // preview modal functions
    openPreviewVideoModal(item, button) {
      this.isShowPreviewModal = true;
      this.previewVideoItem = item;
      this.modalThatBtn = button;

      if (!item.ready) {
        // this.waiting = true;
        // 200 request goes to catch block ?

        // Mark the selected video as waiting
        this.targetVideoItems = this.targetVideoItems.map((video) => {
          if (video._id == item._id) {
            video.waiting = true;
          }
          return video;
        });

        WaitForConversion(item._id)
          .then((res) => {
            // Append a random query parameter to bypass browser's video cache.
            // Otherwise it'll play the old video from cache
            const convertedVideoUrl =
              res.data.content.video + `?t=${Date.now()}`;
            item.videoURL = convertedVideoUrl;
            this.$root.$emit(
              "bv::show::modal",
              "preview-video-row-modal",
              button
            );

            // Set video properties.
            this.targetVideoItems = this.targetVideoItems.map((video) => {
              if (video._id == item._id) {
                video.waiting = false;
                video.ready = true;
                video.videoURL = convertedVideoUrl;
              }
              return video;
            });
          })
          .catch((res) => {
            // Add a random query parameter to bypass video cache.
            const convertedVideoUrl =
              res.data.content.video + `?t=${Date.now()}`;
            item.videoURL = convertedVideoUrl;
            this.$root.$emit(
              "bv::show::modal",
              "preview-video-row-modal",
              button
            );
            this.targetVideoItems = this.targetVideoItems.map((video) => {
              if (video._id == item._id) {
                video.waiting = false;
                video.ready = true;
                video.videoURL = convertedVideoUrl;
              }
              return video;
            });
          });
      } else {
        this.$root.$emit("bv::show::modal", "preview-video-row-modal", button);
      }
    },
    onClosePreviewVideoModal() {
      this.isShowPreviewModal = false;
    },
    // delete functions
    async deleteVideoFn(video) {
      this.deleteApiLoading = true;
      const { data } = await DeleteTargetVideo(video._id);
      if (data.code == 200) {
        let payloadNotify = {
          isToast: true,
          title: "Video Deleted successfully",
          content: "The video has been deleted successfully",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      this.deleteApiLoading = false;
      this.getAllTargetVideos();
      this.isShowDeleteModal = false;
      this.closeDeleteModal();
    },
    async deleteSlideFn(slide) {
      try {
        this.deleteApiLoading = true;
        const { data } = await DeleteComplianceSlide(slide._id);
        if (data.code == 200) {
          let payloadNotify = {
            isToast: true,
            title: "Compliance Slide Deleted successfully",
            content: "The compliance slide has been deleted successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.deleteApiLoading = false;
        this.closeDeleteModal();

        this.getAllComplianceSlides();
      } catch (error) {
        console.log("ERROR V01");
      }
    },
    closeDeleteModal() {
      this.$root.$emit(
        "bv::hide::modal",
        "delete-video-row-modal",
        this.modalThatBtn
      );
    },
    callInitFn() {
      this.showEnableDisableModalKey = Number(new Date()) + 110;
      this.getAllTargetVideos();
    },
  },
};
</script>
<style scoped>
.main-container {
  border: 1px solid var(--stroke-light);
  border-radius: 16px;
}
/* video row, slide row & slide row styling */
.videos-row,
.slides-row,
.configs-row {
  height: auto;
  min-height: 300px;
}

/* video card, slide card & slide card styling */
.videos-row .videos-card {
  border: 1px solid var(--light);
  border-radius: 16px 16px 0 0;
}
.slides-row .slides-card {
  border: 1px solid var(--light);
  border-radius: 0;
}
.configs-row .configs-card {
  border: 1px solid var(--light);
  border-radius: 0 0 16px 16px;
}

.videos-row .videos-card-scroll,
.slides-row .slides-card-scroll {
  width: 100%;
  min-height: 300px;
  background-color: var(--white);
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.videos-row .videos-card-scroll::-webkit-scrollbar,
.videos-row .videos-card-scroll::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.videos-row .videos-card-scroll::-webkit-scrollbar-track,
.videos-row .videos-card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.videos-row .videos-card-scroll::-webkit-scrollbar-thumb,
.videos-row .slides-card-scroll::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* video card & slide card add button styling (with hover state styling) */
.videos-row .videos-card-scroll .video-add-btn,
.slides-row .slides-card-scroll .slide-add-btn {
  width: 180px;
  height: 180px;
  transition: background-color 150ms ease-in;
}
.videos-row .videos-card-scroll .video-add-btn:hover,
.slides-row .slides-card-scroll .slide-add-btn:hover {
  background-color: var(--light) !important;
}

/* video card & slide card styling */
/* single video/slide card */
.videos-row .videos-card-scroll .single-video-card,
.slides-row .slides-card-scroll .single-slide-card {
  position: relative;
  width: 320px;
  height: 180px;
  min-width: 200px;
  min-height: 180px;
  border-radius: 16px;
}
.videos-row .videos-card-scroll .single-video-card >>> div,
.slides-row .slides-card-scroll .single-slide-card >>> div {
  height: 100%;
  width: 100%;
}
.videos-row .videos-card-scroll .single-video-card >>> .card-img,
.slides-row .slides-card-scroll .single-slide-card >>> .card-img {
  min-width: 320px;
  min-height: 180px;
  object-fit: cover;
  transition: opacity 300ms ease-in;
}
/* single video card (disabled state) */
.videos-row .videos-card-scroll .single-video-card.is-disabled >>> .card-img {
  opacity: 30%;
  filter: grayscale(100%);
}
/* video card & slide card inner overlay styling */
/* card body's overlay for video card */
.videos-row .videos-card-scroll .single-video-card >>> .card-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 180px;
  background: linear-gradient(
    0deg,
    /* #2b2b2b 0%, */ #212122 10%,
    rgba(167, 164, 164, 0.1) 60%
  );
}
/* card body's overlay for slide card */
.slides-row .slides-card-scroll .single-slide-card >>> .card-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 180px;
  background: var(--shadow-soft);
  transition: all ease-in 300ms;
}

/* video card & slide card hover state styling */
/* card body's hover state overlay for video card */
.videos-row .videos-card-scroll .single-video-card:hover >>> .card-body:before {
  background: linear-gradient(
    0deg,
    /* #2b2b2b 40%, */ #232324 40%,
    rgba(196, 196, 196, 0.1) 80%
  );
}
/* card body's hover state overlay for slide card */
.slides-row .slides-card-scroll .single-slide-card:hover >>> .card-body:before {
  background: var(--shadow-hard);
}

/* video card & slide card hover actions styling */
.videos-row .videos-card-scroll .single-video-card .hover-actions,
.slides-row .slides-card-scroll .single-slide-card .hover-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 150ms ease-in;
}
.videos-row .videos-card-scroll .single-video-card:hover .hover-actions,
.slides-row .slides-card-scroll .single-slide-card:hover .hover-actions {
  opacity: 1;
}

/* hover action buttons styling */
/* edit icon button (default state) */
.videos-row .videos-card-scroll .single-video-card .hover-actions .preview-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  border: 2px solid var(--white);
  padding: 0 0 0 3px;
  color: var(--white);
  background-color: transparent;
  transition: color, background-color 200ms ease-in;
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .preview-btn:hover {
  color: var(--dark);
  background-color: var(--white);
}
/* edit icon button (default state) */
.videos-row .videos-card-scroll .single-video-card .hover-actions .edit-btn,
.slides-row .slides-card-scroll .single-slide-card .hover-actions .edit-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .edit-btn
  > .edit-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .edit-btn
  > .edit-icon {
  width: 14px;
  height: 14px;
  color: var(--info);
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .edit-btn:hover,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .edit-btn:hover {
  background-color: var(--info);
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .edit-btn:hover
  > .edit-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .edit-btn:hover
  > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.videos-row .videos-card-scroll .single-video-card .hover-actions .delete-btn,
.slides-row .slides-card-scroll .single-slide-card .hover-actions .delete-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .delete-btn
  > .delete-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .delete-btn
  > .delete-icon {
  width: 14px;
  height: 14px;
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .delete-btn:hover,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .delete-btn:hover {
  background-color: var(--danger);
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .delete-btn:hover
  > .delete-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .delete-btn:hover
  > .delete-icon {
  color: var(--white);
}
/* disable icon button (default state) */
.videos-row .videos-card-scroll .single-video-card .hover-actions .disable-btn,
.slides-row .slides-card-scroll .single-slide-card .hover-actions .disable-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .disable-btn
  > .disable-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .disable-btn
  > .disable-icon {
  width: 14px;
  height: 14px;
  transition: color 200ms ease-in;
}
.spinner {
  position: absolute;
  left: 45%;
  top: 40%;
  color: transparent;
}
/* disable icon button (hover state on enabled) */
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .disable-btn.is-enabled:hover,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .disable-btn.is-enabled:hover {
  background-color: var(--dark);
}
/* disable icon button (hover state on disabled) */
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .disable-btn.is-disabled:hover,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .disable-btn.is-disabled:hover {
  background-color: var(--success);
}
.videos-row
  .videos-card-scroll
  .single-video-card
  .hover-actions
  .disable-btn:hover
  > .disable-icon,
.slides-row
  .slides-card-scroll
  .single-slide-card
  .hover-actions
  .disable-btn:hover
  > .disable-icon {
  color: var(--white) !important;
}
.subHeading {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}
</style>
