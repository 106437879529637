<template>
  <!-- Audio Library -->
  <b-row
    class="d-flex flex-column align-items-start justify-content-start px-0 w-100"
    no-gutters
  >
    <b-card
      no-body
      class="d-flex flex-column align-items-start justify-content-start w-100 rounded-0 slides-card"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mt-3 ml-3"
      >
        Audio Library
      </h6>
      <b-row
        class="d-flex flex-row flex-nowrap align-items-start justify-content-start px-3 mt-3 slides-card-scroll w-100"
        no-gutters
      >
        <b-col cols="4" class="pl-1">
          <!-- add audio file -->
          <FormFile
            ref="audioFileUpload"
            groupId="voiceover-file-group"
            id="voiceover-file-group-input"
            class="text-prime-gray"
            :placeholder="'Audio File'"
            isRequired
            trim
            form="slide-configuration-form"
            type="file"
            v-model="audioFile"
            accept="audio/*"
            :state="audioFileError.status"
            :invalidFeedback="`${audioFileError.message}`"
          ></FormFile>

          <span v-if="fileSize" class="text-danger"
            >File size Should be less than 3Mb</span
          >
          <h6
            class="text-left text-prime-gray text-small font-weight-normal font-primary mb-4 ml-3 mt-3"
          >
            Only Upload .mp3, .m3a & .m4a files.
          </h6>

          <FormButton
            id="save-question-btn"
            variant="primary"
            isBlock
            class="font-weight-normal text-white mb-4"
            type="submit"
            v-b-popover.hover
            @click.native="audioUpload"
            :isDisabled="fileSize || isAPILoading"
            :isLoading="isAPILoading"
            >Upload</FormButton
          >
          <!-- <p>{{ currentTime }}/ {{ duration }}</p> -->
        </b-col>
        <b-spinner class="spinner" v-if="isLoading" variant="dark"></b-spinner>
        <!-- Preview audio files -->
        <b-col
          class="d-flex justify-content-start ml-2 w-100 horizontal-scroll ml-4"
        >
          <b-col
            class="col-5 p-0 mr-3 mb-2"
            v-for="(audio, index) in audioList"
            :key="index"
            @mouseover="() => mouseHover(index)"
            @mouseleave="mouseLeave"
          >
            <audio
              controls
              :id="audio._id"
              class="hidden-player"
              @loadedmetadata="onAudioLoaded(audio, index)"
            >
              <source :src="audio.src" type="audio/mpeg" />
            </audio>

            <!-- <button >Play</button> -->
            <b-col
              class="px-3 w-100 hover mr-2 d-flex flex-row align-items-center rounded-lg border border-light shadow-sm py-2"
            >
              <font-awesome-icon
                :icon="
                  !audio.ready
                    ? ['fa', 'clock']
                    : audio.isPlaying
                    ? ['fa', 'pause']
                    : ['fa', 'play-circle']
                "
                class="play-icon"
                v-if="playIcon"
                @click="() => IconChange(audio._id, index)"
              />
              <span class="mx-4 w-100 p-0 d-inline-block text-truncate">{{
                `${audio.title}`
              }}</span>

              <span v-if="audio.isPlaying">{{ audio.currentTime }}/</span>
              <span class="mr-2"> {{ audio.duration }} </span>
              <b-container
                class="p-0 m-0 d-flex align-items-center justify-content-center delete-container"
                v-if="isHovered === index && audio.deletable ? true : false"
              >
                <b-button
                  v-b-tooltip.hover.right
                  title="Delete Audio"
                  size="sm"
                  pill
                  variant="none"
                  class="shadow-none my-1 border border-danger delete-btn bg-none"
                  @click="deleteAudio(audio._id, 'audio', $event.target)"
                >
                  <font-awesome-icon
                    icon="trash"
                    aria-label="Delete Slide"
                    class="delete-icon"
                  ></font-awesome-icon>
                </b-button>
              </b-container>
            </b-col>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
    <!-- <DeleteModal
      :isShow="isShowDeleteModal"
      @onClose="onCloseDeleteItemModal"
      :itemContent="deleteItem"
      :type="deleteType"
    /> -->
  </b-row>
</template>

<script>
import FormFile from "@/components/Form/FormFile";
import FormButton from "@/components/Form/FormButton";
// import DeleteModal from "@/components/Videos/Modals/DeleteModal";
import {
  getAudioLibrary,
  addAudio,
  deleteAudio,
} from "@/services/audioLibrary";
export default {
  name: "AudioLibrary",
  components: {
    FormFile,
    FormButton,
  },
  data() {
    return {
      currentPlayingAudio: null,
      currentPlayingAudioIndex: null,
      currentTime: "00:00",
      isLoading: false,
      isAPILoading: false,
      test: true,
      audioList: [],
      isHovered: null,
      playIcon: true,
      fileSize: false,
      pauseIcon: false,
      file: null,
      audioFile: null,
      isShowDeleteModal: false,
      deleteItem: null,
      deleteType: null,
      audioFileError: {
        status: null,
        message: null,
      },
    };
  },
  mounted() {
    this.getAudio();
  },

  watch: {
    audioFile(val) {
      if (val) {
        if (val.size <= 10 * 1024 * 1024) {
          this.audioFileError.status = true;
        } else {
          this.audioFileError.status = false;
          this.audioFileError.message =
            "Please upload a audio file less than 10 mb";
        }
      } else {
        this.audioFileError.status = false;
        this.audioFileError.message = "Please upload a audio file";
      }
    },
  },

  methods: {
    onAudioLoaded(audio, index) {
      const file = document.getElementById(audio._id);
      const formatDurationTime = this.convertTime(file.duration);
      this.audioList[index].duration = formatDurationTime;
    },
    mouseHover(index) {
      this.isHovered = index;
    },
    mouseLeave() {
      this.isHovered = null;
    },
    IconChange(id, index) {
      this.audioList[index].isPlaying = !this.audioList[index].isPlaying;
      this.toggleAudio(id, index);
    },

    deleteAudio(item, type, button) {
      this.$emit("deleteBtn", { item, type, button });
    },

    toggleAudio(id, index) {
      if (!this.currentPlayingAudio) {
        this.currentPlayingAudio = id;
        this.currentPlayingAudioIndex = index;
      }
      if (!(this.currentPlayingAudio === id)) {
        this.audioList[this.currentPlayingAudioIndex].isPlaying = false;
        const audio = document.getElementById(this.currentPlayingAudio);
        this.currentPlayingAudio = id;
        this.currentPlayingAudioIndex = index;
        audio.pause();
        audio.currentTime = 0;
      }
      var audio = document.getElementById(id);
      audio.ontimeupdate = () => this.currentPlayingTime(audio, index);
      audio.onended = () => this.endPlayingAudio(index);
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    },
    currentPlayingTime(audioPlayer, index) {
      this.audioList[index].currentTime = this.convertTime(
        audioPlayer.currentTime
      );
    },
    endPlayingAudio(index) {
      this.audioList[index].currentTime = "00:00";
      this.audioList[index].isPlaying = false;
    },
    convertTime(seconds) {
      const format = (val) => `0${Math.round(val)}`.slice(-2);
      var minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },

    errorFn() {
      if (!this.audioFile) {
        this.audioFileError.status = false;
        this.audioFileError.message = "Please upload a audio file";
      }
    },

    async getAudio() {
      try {
        this.isLoading = true;
        const data = await getAudioLibrary();
        this.audioList = data.data.content.lib.map((x) => {
          return {
            _id: x._id,
            ready: x.ready,
            title: x.title,
            src: x.src,
            deletable: x.deletable,
            isPlaying: false,
            duration: "00:00",
            currentTime: "00:00",
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async deleteAudioFile(id) {
      try {
        this.isLoading = true;
        this.audioList = [];
        const res = await deleteAudio(id);
        if (res.status == 200) {
          await this.getAudio();
          let payloadNotify = {
            isToast: true,
            title: "Audio deleted",
            content: "Audio deleted successfully.",
            variant: "success",
          };

          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          throw res;
        }
      } catch (error) {
        this.isLoading = false;

        let payloadNotify = {
          isToast: true,
          title: "Something went wrong.",
          content:
            "Something went wrong while deleting the audio file. Please try again later.",
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async audioUpload() {
      if (!this.audioFile) {
        if (!this.audioFile) {
          this.errorFn();
          // handle the error here if either the audio file or title is missing
          return;
        }
      }
      this.isAPILoading = true;
      const formData = new FormData();
      formData.append("audio", this.audioFile);
      const res = await addAudio(formData);

      if (res.status == 200) {
        this.audioFile = null;
        let payloadNotify = {
          isToast: true,
          title: "Success.",
          content: "Audio file uploaded successfully.",
          variant: "success",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.isAPILoading = false;
        await this.getAudio();
        return;
      }
      let payloadNotify = {
        isToast: true,
        title: "Something went wrong.",
        content:
          "Something went wrong while uploading the audio file. Please try again later.",
        variant: "danger",
      };
      this.isAPILoading = false;
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
  },
};
</script>

<style scoped>
.horizontal-scroll {
  overflow-x: auto;
}
.hidden-player {
  display: none;
}

.play-icon {
  width: 35px;
  height: 35px;
}

.hover {
  transition: all 0.3s linear;
}
.hover:hover {
  background: #9fa2b4;
  color: #fff;
}

.delete-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
}

.pause-icon {
  width: 35px;
  height: 35px;
}
.delete-icon {
  width: 14px;
  height: 14px;
  color: red;
}

.display {
  display: block;
}
.display-none {
  display: none;
}

.spinner {
  position: absolute;
  left: 45%;
  top: 30%;
  color: transparent;
}
</style>
