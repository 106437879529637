<template>
  <!-- table container row -->
  <b-row
    class="d-flex flex-column align-items-start justify-content-start w-100 card-row"
    no-gutters
  >
    <h6
      class="text-left text-dark font-weight-semi-bold font-primary mt-3 mb-2 ml-3"
    >
      Compliance slide configuration
    </h6>
    <!-- slide configuration form -->
    <form
      id="slide-configuration-form"
      class="d-flex flex-row flex-wrap align-items-start justify-content-start mx-0 px-0 py-0 h-100 w-100"
    >
      <!-- left column -->
      <b-col
        cols="12"
        lg="6"
        class="d-flex flex-column align-items-start justify-content-start"
      >
      <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
          no-gutters
        >
          <b-button-group>
            <FormButton size="" :isPressed="textstyle.bold"  @click.native="onstylechanged('bold')" variant="outline-primary"><strong>B</strong></FormButton>
            <FormButton size="" :isPressed="textstyle.italic"  @click.native="onstylechanged('italic')" variant="outline-primary"><strong><i> I </i></strong></FormButton>
          </b-button-group>
      </b-row>
        <!-- full row input (Legal Text) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
          no-gutters
        >
          <b-col cols="12" class="mb-4 mb-lg-0">
            <vue-editor
              placeholder="This message has been sponsored by"
              v-model="legalText"
              :state="legalTextError.status"
              :invalidFeedback="`${legalTextError.message}`"
              class="editor w-100"
              id="legalText"
              :editor-toolbar="customToolbar"
            >
            </vue-editor>
            <p class="text-danger text-small mt-2">{{ textcount }}</p>
            <h6 class="small pl-2">
              {{ textcountremain + "/80 characters remaining" }}
            </h6>
          </b-col>
        </b-row>
      </b-col>
      <!-- right column -->
      <b-col
        cols="12"
        lg="6"
        class="d-flex flex-column align-items-start justify-content-start mt-3"
      >
        <!-- half row input (Default Slide Image/Default Slide Placement) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
          no-gutters
        >
          <b-col cols="6" class="pr-1">
            <FormSelect
              groupId="default-slide-group"
              id="default-slide-group-select"
              class="text-prime-gray"
              :state="defaultSlideError.status"
              :invalidFeedback="`${defaultSlideError.message}`"
              label="Default Slide Image"
              v-model="defaultSlide"
              :options="slidesList"
              isRequired
              form="slide-configuration-form"
            ></FormSelect>
          </b-col>
          <b-col cols="6" class="pl-1">
            <FormSelect
              groupId="default-slide-placement-group"
              id="default-slide-placement-group-select"
              class="text-prime-gray"
              :state="slidePlacementError.status"
              :invalidFeedback="`${slidePlacementError.message}`"
              label="Default Slide Placement"
              v-model="slidePlacement"
              :options="slidePlacementList"
              isRequired
              form="slide-configuration-form"
            ></FormSelect>
          </b-col>
        </b-row>
        <!-- half row input (Slide Duration/Default Text Placement) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
          no-gutters
        >
          <b-col cols="6" class="pr-1">
            <FormSelect
              groupId="slide-duration-group"
              id="slide-duration-group-input"
              class="text-prime-gray"
              :state="slideDurationError.status"
              :invalidFeedback="`${slideDurationError.message}`"
              label="Slide Duration"
              v-model="slideDuration"
              :options="slideDurationList"
              isRequired
              form="slide-configuration-form"
            ></FormSelect>
          </b-col>
          <b-col cols="6" class="pl-1">
            <FormSelect
              groupId="default-text-placement-group"
              id="default-text-placement-group-select"
              class="text-prime-gray"
              :state="textPlacementError.status"
              :invalidFeedback="`${textPlacementError.message}`"
              label="Default Text Placement"
              v-model="textPlacement"
              :options="textPlacementList"
              isRequired
              form="slide-configuration-form"
            ></FormSelect>
          </b-col>
        </b-row>
        <!-- half row input (Slide Voiceover/Voiceover Playback) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
          no-gutters
        >
          <b-col cols="6" class="pr-1">
            <b-dropdown
              id="dropdown-form"
              ref="dropdown"
              variant="light"
              class="w-100 py-0 m-0 filter-dropdown-element"
              toggle-class="border bg-white rounded-lg"
              menu-class="w-100 bg-white border border-prime-gray py-0 filter-dropdown-menu thin-scrollbar"
              left
              no-caret
              no-flip
            >
              <template v-slot:button-content>
                <div
                  class="d-flex align-items-center justify-content-between w-100 filter-dropdown-button-element"
                >
                  <span
                    class="text-left text-prime-gray font-primary"
                    v-bind:style="{ fontFamily: selected }"
                  >
                    {{
                      selected && selected.family != "null"
                        ? selected.family
                        : "Select Font"
                    }}
                  </span>
                  <font-awesome-icon
                    class="text-prime-gray px-0 w-auto ml-2"
                    icon="angle-down"
                    aria-label="Help"
                  ></font-awesome-icon>
                </div>
              </template>
              <b-dropdown-group flush class="rounded-lg py-0">
                <b-row
                  class="d-flex flex-row align-items-center justify-content-center w-100"
                  no-gutters
                >
                </b-row>
                <div class="w-100 account-dropdown">
                  <template>
                    <b-row
                      class="w-100 d-flex flex-row align-items-center justify-content-start"
                      no-gutters
                    >
                      <b-col
                        class="w-100 d-flex flex-column align-items-stretch justify-content-center px-0"
                      >
                        <!-- list all the suppliers here -->
                        <template v-for="(item, index) in fonts">
                          <b-dropdown-item-button
                            :key="index"
                            @click="changeFont(item), (selected = item)"
                            class="w-100"
                            button-class="d-flex flex-column align-items-start justify-content-center px-3 py-2 dropdown-item-button"
                          >
                            <span
                              class="text-left text-dark font-primary"
                              v-bind:style="{
                                fontFamily: item.family,
                              }"
                            >
                              {{ item.family }}
                            </span>
                          </b-dropdown-item-button>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </div>
              </b-dropdown-group>
            </b-dropdown>
          </b-col>
          <b-col cols="6" class="pl-1">
            <FormFile
              ref="audioFileUpload"
              groupId="voiceover-file-group"
              id="voiceover-file-group-input"
              class="text-prime-gray"
              :state="slideVoiceOverError.status"
              :invalidFeedback="`${slideVoiceOverError.message}`"
              :placeholder="
                slideVoiceOver
                  ? getFileName(slideVoiceOver)
                  : 'Slide Voice-Over'
              "
              v-model="slideVoiceOver"
              isRequired
              trim
              form="slide-configuration-form"
              type="file"
              :title="voiceDurationText"
              @change="audioAdded()"
            ></FormFile>
            <h6
              class="text-left text-prime-gray text-small font-weight-normal font-primary mb-4 ml-3 mt-3"
            >
              Please use .mp3, .m3a, .mp4, .m4a, .avi and .wma files
            </h6>
            <b-tooltip
              id="voice-helper-tooltip"
              target="voiceover-file-group"
              triggers="manual"
              placement="top"
              boundary="window"
              offset="0"
            >
              {{ voiceDurationText }}
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
      <!-- form actions -->
      <b-col
        cols="12"
        class="d-flex flex-column align-items-start justify-content-start my-2"
      >
        <!-- form action -->
        <b-row
          class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-end w-100"
          no-gutters
        >
          <!-- cancel button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center pr-0 pr-md-2 py-2"
            cols="12"
            md="4"
            lg="3"
            xl="2"
          >
            <FormButton
              variant="light"
              isBlock
              class="font-weight-bold text-secondary mr-4"
              type="reset"
              @click.native="resetFormFn()"
              :isLoading="isResetLoading"
              >Reset</FormButton
            >
          </b-col>
          <!-- save and exit button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center pl-0 pl-md-2 py-2"
            cols="12"
            md="4"
            lg="3"
            xl="2"
          >
            <FormButton
              id="save-question-btn"
              variant="primary"
              isBlock
              :disabled="isAPILoading"
              :isLoading="isAPILoading"
              class="font-weight-normal text-white"
              type="submit"
              @click.native="updateSlideConfigFn()"
              :loadingText="uploadProgress"
              v-b-popover.hover
              :title="apiConfirmationText"
              >Update</FormButton
            >
            <b-tooltip
              id="helper-tooltip"
              target="save-question-btn"
              triggers="manual"
              placement="top"
              boundary="window"
              offset="0"
            >
              {{ apiConfirmationText }}
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </form>

    <!-- END Custom Toolbar -->

    <!-- overlay loader -->
    <b-overlay :show="initLoading" no-wrap></b-overlay>
  </b-row>
</template>

<script>
// @ is an alias to /src

// components
// import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormButton from "@/components/Form/FormButton";
import FormFile from "@/components/Form/FormFile";

// import axios from "axios";

// services
import {
  GetSlideConfiguration,
  UpdateSlideConfiguration,
} from "@/services/slideConfiguration.service";
import { GetAllComplianceSlides } from "@/services/complianceSlides.service";

import Fonts from "@/data/fonts"

export default {
  name: "SlideConfiguration",
  components: {
    // FormInput,
    FormSelect,
    FormFile,
    FormButton,
  },
  data() {
    return {
      id: null,
      legalText: "This message has been sponsored by",
      textstyle:{
        bold: false,
        italic: false
      },
      defaultSlide: null,
      slidesList: [],
      slidePlacement: null,
      textcount: null,
      textcountremain: 0,
      slidePlacementList: [
        { value: "start", text: "Start of the video" },
        { value: "end", text: "End of the video" },
      ],
      textPlacement: null,
      textPlacementList: [
        { value: "center", text: "Center" },
        { value: "top", text: "Top" },
        { value: "bottom", text: "Bottom" },
        // { value: "left", text: "Left" },
        // { value: "right", text: "Right" },
      ],
      slideDuration: null,
      slideDurationList: [
        // { value: 5, text: "5 seconds" },
        // { value: 10, text: "10 seconds" },
        // { value: 15, text: "15 seconds" },
        { value: 1, text: "1 seconds" },
        { value: 2, text: "2 seconds" },
        { value: 3, text: "3 seconds" },
        { value: 4, text: "4 seconds" },
        { value: 5, text: "5 seconds" },
        { value: 6, text: "6 seconds" },
        { value: 7, text: "7 seconds" },
        { value: 8, text: "8 seconds" },
        { value: 9, text: "9 seconds" },
        { value: 10, text: "10 seconds" },
      ],
      slideVoiceOver: null,
      newVoiceOver: false,
      initLoading: false,
      isAPILoading: false,
      uploadProgress: "",
      isLoading: false,
      apiConfirmationText: null,
      voiceDurationText: null,
      legalTextError: {
        status: null,
        message: "",
      },
      defaultSlideError: {
        status: null,
        message: "",
      },
      slidePlacementError: {
        status: null,
        message: "",
      },
      textPlacementError: {
        status: null,
        message: "",
      },
      slideDurationError: {
        status: null,
        message: "",
      },
      slideVoiceOverError: {
        status: null,
        message: "",
      },
      selected: null,
      selectedFontUrl: null,
      selectedFontFamily: null,
      assestBaseUrl: null,
      fonts: Fonts,
      customToolbar: [
        []
        // ["bold", "italic", "underline"],
        // [{ list: "ordered" }, { list: "bullet" }],
        // [{ font: fonts.whitelist }],
      ],

      aboutText: null,
      aboutTextTextError: {
        status: null,
        message: null,
      },
      // tempSlideVoiceOver: null,
      isReset: false,
      isResetLoading: false,
    };
  },
  async mounted() {
    // start and end the loading when all the APIs are called
    this.initLoading = true;
    await this.getComplianceSlideList();
    await this.getSlideConfiguration();
    this.initLoading = false;

    this.assestBaseUrl =
      process.env.VUE_APP_ASSETS ||
      "https://storage.googleapis.com/personalpac";
  },
  watch: {
    legalText(val) {
      if (!this.isReset) {
        val = val.replace(/<[^>]+>/g, "");

        if (val[0] == undefined) {
          this.textcountremain = 0;
        }

        if (val) {
          this.legalTextError.status = true;
          if (val.length <= 80) {
            this.legalTextError.status = true;
            this.textcountremain = 80 - val.length;
            this.textcount = null;
          } else {
            this.legalTextError.status = false;
            this.legalTextError.message =
              "Title should have less than 80 characters";
            this.textcount = "Title should have less than 80 characters";
            this.textcountremain = 0;
          }
        } else {
          this.legalTextError.status = false;
          this.legalTextError.message = "Please fill in the legal text";
        }
      }
    },
    defaultSlide(val) {
      if (!this.isReset) {
        if (val) {
          this.defaultSlideError.status = true;
        } else {
          this.defaultSlideError.status = false;
          this.defaultSlideError.message =
            "Please choose the default slide image";
        }
      }
    },
    slidePlacement(val) {
      if (!this.isReset) {
        if (val) {
          this.slidePlacementError.status = true;
        } else {
          this.slidePlacementError.status = false;
          this.slidePlacementError.message =
            "Please choose the default slide placement";
        }
      }
    },
    textPlacement(val) {
      if (!this.isReset) {
        if (val) {
          this.textPlacementError.status = true;
        } else {
          this.textPlacementError.status = false;
          this.textPlacementError.message =
            "Please choose the default text placement";
        }
      }
    },
    slideDuration(val) {
      if (!this.isReset) {
        if (val) {
          this.slideDurationError.status = true;
          // show popover when slide duration selected
          // trigger popover
          this.voiceDurationText = `Please make sure the mp3 file's duration is longer than or equal to ${val} seconds`;
          this.$root.$emit("bv::show::tooltip", "voice-helper-tooltip");
          setTimeout(() => {
            this.$root.$emit("bv::hide::tooltip", "voice-helper-tooltip");
            this.voiceDurationText = null;
          }, 5000);
          // clear any error on audio duration and mark form file as valid
          this.slideVoiceOverError.status = true;
          this.slideVoiceOverError.message = "";
        } else {
          this.slideDurationError.status = false;
          this.slideDurationError.message = "Please enter the slide duration";
          this.$root.$emit("bv::hide::tooltip", "voice-helper-tooltip");
          this.voiceDurationText = null;
        }
      }
    },
    slideVoiceOver(val) {
      if (!this.isReset) {
        // validate if it is a file
        if ("File" in window && val instanceof File) {
          // check if it is a mp3/mp4 audio file
          // if (
          //   (val.type && val.type == "audio/mpeg") ||
          //   val.type == "audio/mp3" ||
          //   val.type == "audio/mp4" ||
          //   val.type == "audio/x-m4a"
          // ) {
          // check if the audio's size is less than 10mb
          if (val.size && val.size < 10 * 1024 * 1024) {
            // instantialize the file reader and add onload event
            const reader = new FileReader();
            // let audio = document.createElement("audio");
            let audio = document.getElementById("audio-player-element");
            let audioDuration = null;
            reader.onload = (e) => {
              audio.src = e.target.result;
              audioDuration = audio.duration;
              audio.addEventListener(
                "durationchange",
                () => {
                  // get the audio duration from here
                  audioDuration = audio.duration;

                  // assign audio duration to the slide duration object
                  this.slideVoiceOver.duration = audioDuration;

                  if (audioDuration && audioDuration < this.slideDuration) {
                    this.slideVoiceOverError.status = false;
                    this.slideVoiceOverError.message = `Please upload a audio file that is more than ${this.slideDuration} seconds in duration`;
                  } else {
                    // clear any error and mark form file as valid
                    this.slideVoiceOverError.status = true;
                    this.slideVoiceOverError.message = "";
                  }
                },
                false
              );
            };

            // read the file as a data url
            reader.readAsDataURL(val);
          } else {
            this.slideVoiceOverError.status = false;
            this.slideVoiceOverError.message =
              "Please upload a audio file that is less than 10MB in size";
          }
          // } else {
          //   this.slideVoiceOverError.status = false;
          //   this.slideVoiceOverError.message =
          //     "Please upload a valid audio file in the .mp3 / .mp4 / .m4a format";
          // }
        } else {
          this.slideVoiceOverError.status = false;
          this.slideVoiceOverError.message = "Please upload a valid file";
        }
      }
    },
  },
  computed: {
    computeAudioFileValidation() {
      // check if the audio file is a valid audio file in the mp3 format
      if (this.slideVoiceOver) {
        if (
          // ("File" in window &&
          //   this.slideVoiceOver instanceof File &&
          //   this.slideVoiceOver.type &&
          //   this.slideVoiceOver.type == "audio/mpeg") ||
          // this.slideVoiceOver.type == "video/mp4" ||
          // (this.slideVoiceOver.type == "audio/x-m4a" ||
          //   this.slideVoiceOver.duration &&
          //   this.slideVoiceOver.duration > this.slideDuration &&
          this.slideVoiceOver.size &&
          this.slideVoiceOver.size < 10 * 1024 * 1024
        ) {
          return true;
        }
        return false;
      } else return false;
    },
  },
  methods: {
    onstylechanged(style){
      this.textstyle[style] = !this.textstyle[style] 
      if(this.selected){
        console.log("Applying style")
        this.changeFont(this.selected);
      }
    },
    updateProgress(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.uploadProgress = `Updating ${percentCompleted}%`;
    },
    async getSlideConfiguration() {
      try {
        this.isLoading = true;
        let { data } = await GetSlideConfiguration();
        if (data.code == 200 || data.message == "success") {
          let result = data.content;
          if (result) {
            this.id = result._id;
            this.legalText = result.legalText
              ? result.legalText
              : "This message has been sponsored by";
            this.slideDuration = result.slideDuration;
            this.textPlacement = result.textPlacement
              ? result.textPlacement
              : null;
            this.slidePlacement = result.slidePlacement
              ? result.slidePlacement
              : null;
            this.defaultSlide = result.slideImage
              ? this.getDefaultSlide(result.slideImage)
              : null;

            this.slideVoiceOver = await this.getFileFromURL(
              result.slideVoiceOver,
              "slideVoiceOver.mp3",
              "audio/mpeg"
            );
            // this.tempSlideVoiceOver = await this.getFileFromURL(
            // result.slideVoiceOver,
            // "slideVoiceOver.mp3",
            // "audio/mpeg"
            // );

            if(result.fontUrl.includes("Bold")){
              this.textstyle.bold = true
            }
            if(result.fontUrl.includes("Italic")){
              this.textstyle.italic = true
            }

            this.selected = this.fonts.find(f=> f.family = result.fontFamily)

            await this.changeFont(this.selected);
          }
        }
      } catch (error) {
        console.log("ERROR SC1");
      } finally {
        this.isLoading = false;
      }
    },
    audioAdded() {
      this.newVoiceOver = true;
    },
    async getFileFromURL(url, name, defaultType = "image/jpeg") {
      if (url && url.length > 0) {
        try {
          const response = await fetch(url);
          // const data = await response.blob();
          return new File([response], name, {
            type: response.headers.get("content-type") || defaultType,
          });
        } catch (error) {
          console.log("ERROR SC2");
          return null;
        }
      } else {
        return null;
      }
    },
    // get file name from file object
    getFileName(object) {
      if (object) return object.name;
      return "Audio File";
    },
    async getComplianceSlideList() {
      try {
        this.isLoading = true;
        let { data } = await GetAllComplianceSlides();
        if (data.code == 200 || data.message == "success") {
          this.slidesList = data.content.results.map((x) => {
            return {
              value: {
                slideBackgroundImage: x.slideBackgroundImage,
                textColor: x.textColor,
                type: x.type,
              },
              text:
                x.type == "normal"
                  ? "(Slide)" + "  " + x.slideTitle
                  : "(Image)" + "  " + x.slideTitle,
            };
          });
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Compliance Slides",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isLoading = false;
        console.log("ERROR SC3");
      }
    },
    async updateSlideConfigFn() {
      try {
        this.isAPILoading = true;
        if (
          this.legalText &&
          this.legalText.length <= 87 &&
          this.slideDuration &&
          this.slidePlacement &&
          this.defaultSlide.slideBackgroundImage &&
          // this.defaultSlide.textColor &&
          this.textPlacement &&
          this.computeAudioFileValidation
        ) {
          // show popover when API is loading
          // trigger popover
          if (this.isAPILoading) {
            this.apiConfirmationText =
              "Please wait! This might take a few seconds";
            this.$root.$emit("bv::show::tooltip", "helper-tooltip");
          }
          // prepare the payload with FormData (along with the id)
          const formData = new FormData();

          const editor = document.getElementsByClassName("ql-editor")[0];
          const rawLegalText = editor.innerText;

          formData.append("legalText", rawLegalText);
          formData.append("slideDuration", this.slideDuration);
          formData.append("slideImage", this.defaultSlide.slideBackgroundImage);
          formData.append("slidePlacement", this.slidePlacement);
          if (this.newVoiceOver)
            formData.append("slideVoiceOver", this.slideVoiceOver);
          formData.append("textPlacement", this.textPlacement);
          formData.append("textColor", this.defaultSlide.textColor);
          formData.append("type", this.defaultSlide.type);
          formData.append("fontFamily", this.selectedFontFamily);
          formData.append("fontUrl", this.selectedFontUrl);

          // no need to send payload id (the id is handled on the backend according to the environment)
          let { data } = await UpdateSlideConfiguration(
            formData,
            this.updateProgress
          );
          if (data.code == 200 || data.message == "success") {
            // hide the tooltip
            this.apiConfirmationText = null;
            this.$root.$emit("bv::hide::tooltip", "helper-tooltip");

            let payloadNotify = {
              isToast: true,
              title: "Slide Configuration Updated",
              content: "The slide configuration has been updated successfully",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Slide Configuration",
              content: data.details,
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } else {
          this.errorFn();
        }
      } catch (error) {
        console.log("ERROR SC4");
      } finally {
        this.isAPILoading = false;
      }
    },
    getDefaultSlide(slideUrl) {
      let obj = this.slidesList.find(
        (x) => x.value.slideBackgroundImage == slideUrl
      );
      return obj && obj.value ? obj.value : "";
    },
    errorFn() {
      // error list to have an array of all the errors to be displayed as a list
      let errorList = [];
      // use .push() to append string as an array element
      if (!this.legalText) {
        this.legalTextError.status = false;
        this.legalTextError.message = "Please fill in the legal text";

        errorList.push(this.legalTextError.message);
      } else if (this.legalText && this.legalText.length > 80) {
        this.legalTextError.status = false;
        this.legalTextError.message =
          "Title should have less than 80 characters";

        errorList.push(this.legalTextError.message);
      }
      if (!this.defaultSlide) {
        this.defaultSlideError.status = false;
        this.defaultSlideError.message =
          "Please choose the default slide image";

        errorList.push(this.defaultSlideError.message);
      }
      if (!this.slidePlacement) {
        this.slidePlacementError.status = false;
        this.slidePlacementError.message =
          "Please choose the default slide placement";

        errorList.push(this.slidePlacementError.message);
      }
      if (!this.textPlacement) {
        this.textPlacementError.status = false;
        this.textPlacementError.message =
          "Please choose the default text placement";

        errorList.push(this.textPlacementError.message);
      }
      if (!this.slideDuration) {
        this.slideDurationError.status = false;
        this.slideDurationError.message = "Please enter the slide duration";

        errorList.push(this.slideDurationError.message);
      }
      // audio file validation
      if (!this.slideVoiceOver) {
        this.slideVoiceOverError.status = false;
        this.slideVoiceOverError.message = "Please upload the slide voice over";

        errorList.push(this.slideVoiceOverError.message);
      } else {
        if ("File" in window && this.slideVoiceOver instanceof File == false) {
          this.slideVoiceOverError.status = false;
          this.slideVoiceOverError.message =
            "Please upload a valid file as the audio file";

          errorList.push(this.slideVoiceOverError.message);
        }
        // if (
        //   (this.slideVoiceOver.type &&
        //     this.slideVoiceOver.type != "audio/mpeg") ||
        //   this.slideVoiceOver.type == "audio/mp3" ||
        //   this.slideVoiceOver.type == "audio/mp4" ||
        //   this.slideVoiceOver.type == "audio/x-m4a"
        // ) {
        //   this.slideVoiceOverError.status = false;
        //   this.slideVoiceOverError.message =
        //     "Please upload a valid audio file in the .mp3 / .mp4 / .m4a format";

        //   errorList.push(this.slideVoiceOverError.message);
        // }
        if (
          this.slideVoiceOver.size &&
          this.slideVoiceOver.size > 10 * 1024 * 1024
        ) {
          this.slideVoiceOverError.status = false;
          this.slideVoiceOverError.message =
            "Please upload a audio file that is less than 10MB in size";

          errorList.push(this.slideVoiceOverError.message);
        }
        if (
          this.slideVoiceOver.duration &&
          this.slideVoiceOver.duration < this.slideDuration
        ) {
          this.slideVoiceOverError.status = false;
          this.slideVoiceOverError.message = `Please upload a audio file that is more than ${this.slideDuration} seconds in duration`;

          errorList.push(this.slideVoiceOverError.message);
        }
      }
      // overall error message
      let payloadNotify = {
        isToast: true,
        title: "Error: Unable to update the slide configuration",
        content: "Please resolve the following:",
        variant: "danger",
        list: errorList,
        duration: "long",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    async resetFormFn() {
      // reset the data parameters
      try {
        // this.getSlideConfiguration();
        this.isReset = true;
        if (
          this.legalText ||
          this.defaultSlide ||
          this.slideVoiceOver ||
          this.slidePlacement ||
          this.slideDuration ||
          this.textPlacement
        ) {
          1;
          this.isResetLoading = true;
          const formData = new FormData();

          formData.append("legalText", "");
          formData.append("slideDuration", "");
          formData.append("slideImage", "");
          formData.append("slidePlacement", "");
          formData.append("slideVoiceOver", "");
          formData.append("textPlacement", "");
          formData.append("textColor", "");
          formData.append("fontFamily", "");
          formData.append("fontUrl", "");

          let { data } = await UpdateSlideConfiguration(formData);
          if (data.code == 200 || data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Slide Configuration Reset",
              content: "The slide configuration has been reset successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            await this.getComplianceSlideList();
            await this.getSlideConfiguration();
            this.legalTextError.status = null;
            this.defaultSlideError.status = null;
            this.slidePlacementError.status = null;
            this.textPlacementError.status = null;
            this.slideDurationError.status = null;
            this.slideVoiceOverError.status = null;
            this.textcountremain = 0;
            this.isResetLoading = false;
            this.isReset = false;
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Slide Configuration",
              content: data.details,
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        }
      } catch (error) {
        console.log("ERROR SC5");
      }

      // this.id = null;
      // this.legalText = null;
      // this.defaultSlide = null;
      // this.slidePlacement = null;
      // this.textPlacement = null;
      // this.slideDuration = null;
      // this.slideVoiceOver = null;

      // // reset the loading states
      // this.isAPILoading = false;

      // // reset any other text
      // this.apiConfirmationText = null;

      // // reset error parameters
      // this.legalTextError = {
      //   status: null,
      //   message: "",
      // };
      // this.defaultSlideError = {
      //   status: null,
      //   message: "",
      // };
      // this.slidePlacementError = {
      //   status: null,
      //   message: "",
      // };
      // this.textPlacementError = {
      //   status: null,
      //   message: "",
      // };
      // this.slideDurationError = {
      //   status: null,
      //   message: "",
      // };
      // this.slideVoiceOverError = {
      //   status: null,
      //   message: "",
      // };
    },
    async changeFont(selected) {
      let fontUrl = selected.files.regular;
      if(this.textstyle.bold && this.textstyle.italic){
        fontUrl = selected.files.bold_italic;
      }else if(this.textstyle.bold){
        fontUrl = selected.files.bold;
      }else if(this.textstyle.italic){
        fontUrl = selected.files.italic;
      }

      if (!fontUrl.startsWith(process.env.VUE_APP_ASSETS))
      fontUrl = `${process.env.VUE_APP_ASSETS}${fontUrl}`;
    
      const url = new URL(fontUrl);
      if (location.protocol == "http") url.protocol = "http";
      else url.protocol = "https";
      const font = new FontFace(selected.family, `url("${url}")`);

      try {
        const loadedFont = await font.load();
        document.fonts.add(loadedFont);
        this.selectedFontUrl = fontUrl;
        this.selectedFontFamily = selected.family;
      } catch (error) {
        console.error(error);
      }

      var search_span = document.getElementsByClassName("ql-editor");
      search_span[0].style.fontFamily = selected.family;
    },
  },
};
</script>

<style scoped>
/* container card */
.card-row {
  height: 100%;
}
.card-row .card-container {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px;
  border: 1px solid var(--light);
}
.form-file {
  color: var(--prime-gray);
  border: violet;
}
/* placeholder text color */
.form-file >>> .form-control::placeholder {
  color: var(--prime-gray);
}
/* custom checkbox */
.custom-checkbox,
.custom-checkbox-active {
  cursor: pointer;
}
/* checkbox color (default state) */
.custom-checkbox >>> .custom-control-label,
.custom-checkbox-active >>> .custom-control-label {
  user-select: none;
}
.custom-checkbox >>> .custom-control-label::before {
  background-color: var(--ghost-white);
  border-color: transparent;
}
.custom-checkbox >>> .custom-control-label::after {
  background-color: var(--white);
}
/* override default bootstrap styles */
.custom-checkbox
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}
.custom-checkbox
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}
/* checkbox color (active state) */
.custom-checkbox-active >>> .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}
/* override default bootstrap styles */
.custom-checkbox-active
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}
.custom-checkbox-active
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}
.custom-checkbox-active
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}
.image-uploader-row {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.image-uploader-row .remove-button {
  width: 400px;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.image-uploader-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.image-uploader-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.image-uploader-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
/* audio player */
.audio-player-container {
  height: 50px;
}
#audio-player-element.audio-player {
  max-width: 100%;
  max-height: 50px;
}
.editor >>> .ql-tooltip {
  z-index: 50;
}
.editor >>> .ql-tooltip .ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;
  height: 200px;
}
.editor >>> .ql-snow .ql-picker-label {
  width: 160px;
}
.editor >>> .ql-snow .ql-picker-options {
  min-width: 160px;
  margin-top: 10px;
  border-radius: 5px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* filter dropdown */
.filter-dropdown-element {
  height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
/* truncate text */
.filter-dropdown-element >>> .filter-dropdown-button-element > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-dropdown-element >>> .filter-dropdown-menu {
  min-width: 250px;
  margin-top: 10px;
  border-radius: 5px;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* dropdown search element */
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input {
  box-shadow: none;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input::placeholder {
  color: var(--primary);
  font-weight: bold;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-item-button
  > span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input::placeholder {
  color: var(--secondary);
  font-weight: bold;
  box-shadow: var(--secondary);
}
/* .testing{
  width:200px;
} */
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .image-uploader-row {
    padding: 1rem 0;
  }
}
</style>
